import React, { useContext, useEffect, useState, useRef } from "react"
import { ContextState } from "./../../context/Context"
import { snakeCase } from "lodash"

import ScrollSection from "../locomotiveScroll/scrollSection"
import JsonText from "../_jsonText"

import styles from "./whitepaper-content.module.scss"

const WhitepaperContent = ({ sections }) => {
  const { locomotiveInit } = useContext(ContextState)
  const wrapperRef = useRef()
  const currentElementRef = useRef()
  const [navigationTheme, setNavigationTheme] = useState(
    sections[0].colourScheme.toLowerCase()
  )
  const [activeSection, setActiveSection] = useState(
    snakeCase(sections[0].sectionTitle)
  )
  const [scrollProgress, setScrollProgress] = useState(0)

  const scrollToSection = e => {
    e.preventDefault()

    const targetSelector = e.target.getAttribute("href")
    window.scroll.scrollTo(targetSelector)
  }

  useEffect(() => {
    if (!window.scroll?.on) {
      return
    }

    const handleScroll = e => {
      const currentElements = Object.values(e.currentElements)

      // Start by updating the scroll progress
      const containerElement = currentElements.find(e => e.id === "container")
      setScrollProgress(containerElement?.progress)

      // Now deal with the active element detection
      const filteredElements = currentElements.filter(e => {
        if (!e?.id.startsWith) {
          return
        }

        return !e.id.startsWith("el") && e.id !== "container"
      })
      const currentElement = filteredElements?.[0]
      const currentElementId = currentElement?.id

      if (currentElementId && currentElementId !== currentElementRef.current) {
        setActiveSection(currentElementId)
        setNavigationTheme(currentElement.el.dataset.theme)

        currentElementRef.current = currentElementId
      }
    }

    window.scroll.on("scroll", handleScroll)

    return () => {
      window.scroll.off("scroll", handleScroll)
    }
  }, [locomotiveInit])

  useEffect(() => {
    const wrapper = wrapperRef.current

    const resizeObserver = new ResizeObserver(entries => {
      if (window.scroll?.update) {
        window.scroll.update()
      }
    })
    resizeObserver.observe(wrapper)
  }, [locomotiveInit])

  return (
    <ScrollSection>
      <div id="contentContainer" ref={wrapperRef}>
        <div
          className={styles.progressBar}
          data-scroll
          data-scroll-sticky
          data-scroll-target="#contentContainer"
        >
          <div
            className={styles.progressBarInner}
            style={{ transform: `scaleX(${scrollProgress})` }}
          />
        </div>

        <div className={`container ${styles.whitepaperContent}`}>
          <div
            id="articleContainer"
            className="cols flex flex--center flex--vert-align-top"
            data-scroll
            data-scroll-id="container"
            data-scroll-offset="0,50%"
          >
            <div
              className={`col m12 t4 d3 ${styles.navigationContainer}`}
              data-theme={navigationTheme}
              data-scroll
              data-scroll-sticky
              data-scroll-target="#articleContainer"
            >
              {sections.length > 1 &&
                React.Children.toArray(
                  sections.map(section => {
                    return (
                      <a
                        href={"#" + snakeCase(section.sectionTitle)}
                        className={styles.navigationLink}
                        data-active={
                          activeSection === snakeCase(section.sectionTitle)
                            ? true
                            : null
                        }
                        onClick={scrollToSection}
                      >
                        {section.sectionTitle}
                      </a>
                    )
                  })
                )}
            </div>

            <div className="col m12 t8 d7">
              <article className={styles.articleContainer}>
                {React.Children.toArray(
                  sections.map(section => {
                    return (
                      <div
                        id={snakeCase(section.sectionTitle)}
                        className={styles.sectionContainer}
                        data-theme={section.colourScheme.toLowerCase()}
                        data-scroll
                        data-scroll-id={snakeCase(section.sectionTitle)}
                        data-scroll-repeat
                        data-scroll-call="sectionChange"
                        data-scroll-offset="90%,100"
                      >
                        <h2 className={styles.sectionHeader}>
                          {section.sectionTitle}
                        </h2>

                        <div className={styles.richTextContainer}>
                          <JsonText content={section.sectionContent} />
                        </div>
                      </div>
                    )
                  })
                )}
              </article>
            </div>
          </div>
        </div>
      </div>
    </ScrollSection>
  )
}

export default WhitepaperContent
